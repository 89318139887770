import React from 'react';

const Footer = () => {

    return (
        <footer>
            <div className="container">
                <p>&copy; Treasure Beach Digital. All Rights Reserved.</p>
                <ul className="list-inline">
                    <li className="list-inline-item">
                        <a href="https://dmo-console.opencaribbean.org/privacy-policy">Privacy Policy</a>
                    </li>
                   
                </ul>
            </div>
        </footer>
    )

}

export default Footer;