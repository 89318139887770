import React from 'react';

const Header = () => {

    return (
        <header className="masthead">
            <div className="container h-100">
                <div className="row h-100">
                    <div className="col-lg-7 my-auto">
                        <div className="header-content mx-auto">
                            <h1 className="mb-5">With Treasure Beach App it is now easier to find places of interest for sightseeing in Jamaica!</h1>
                            <a href="#download" className="btn btn-outline btn-xl js-scroll-trigger">Install Now!</a>
                        </div>
                    </div>
                    <div className="col-lg-5 my-auto">
                        <div className="device-container">
                            <div className="device-mockup iphone6_plus portrait white">
                                <div className="device">
                                    <div className="screen">
                                        <img src="img/demo-screen-1.jpg" className="img-fluid" alt="" />
                                    </div>
                                    <div className="button">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )

}

export default Header;