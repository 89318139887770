import React from 'react';

const SectionStarter = () => {

    return (
        <section className="cta">
            <div className="cta-content">
                <div className="container">
                    <h2>Let life flow.</h2>
                    <a href="#contact" className="btn btn-outline btn-xl js-scroll-trigger">Let's Get Started!</a>
                </div>
            </div>
            <div className="overlay"></div>
        </section>
    )

}

export default SectionStarter;