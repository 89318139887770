import React from 'react';

const SectionDownload = () => {

    return (
        <section className="download bg-primary text-center" id="download">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 mx-auto">
                        <h2 className="section-heading">Discover what all the buzz is about!</h2>
                        <p>Our app is available on any mobile device! Download now to get started!</p>
                        <div className="badges">
                            <a className="badge-link" href="https://drive.google.com/open?id=1DCpAc7lPLlzna24vq7KR7NVZVnmtskT5"><img src="img/download-here.png" alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default SectionDownload;