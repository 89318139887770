import React from 'react';

const SectionFeature = () => {

    return (
        <section className="features" id="features">
            <div className="container">
                <div className="section-heading text-center">
                    <h2>Unlimited Features, Unlimited Fun</h2>
                    <p className="text-muted">See what you can do with this fantastic application!!</p>
                    <hr />
                </div>
                <div className="row">
                    <div className="col-lg-4 my-auto">
                        <div className="device-container">
                            <div className="device-mockup iphone6_plus portrait white">
                                <div className="device">
                                    <div className="screen">
                                        <img src="img/demo-screen-1.jpg" className="img-fluid" alt="" />
                                    </div>
                                    <div className="button">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 my-auto">
                        <div className="container-fluid">
                            <div className="row">                              
                                <div className="col-lg-6">
                                    <div className="feature-item">
                                        <i className="fa fa-search text-primary"></i>
                                        <h3>Find quickly</h3>
                                        <p className="text-muted">Find places of interest based on their features, opening hours or types!</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="feature-item">
                                        <i className="fa fa-map-marker text-primary"></i>
                                        <h3>Mark places</h3>
                                        <p className="text-muted">Discover how to get to your favorite places from where you are!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="feature-item">
                                        <i className="fa fa-bullhorn text-primary"></i>
                                        <h3>Advertise your business</h3>
                                        <p className="text-muted">You can promote your business and thus increase the number of visitors very easily!</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="feature-item">
                                        <i className="fa fa-bell text-primary"></i>
                                        <h3>Event Notification</h3>
                                        <p className="text-muted">Receive notifications about events in places of interest at all times!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default SectionFeature;