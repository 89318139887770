import React from 'react';

const SectionSocial = () => {

    return (
        <section className="contact bg-primary" id="contact">
            <div className="container">
                <h2>Follow us or share your experience in Treasure Beach!</h2>
                <ul className="list-inline list-social">
                    <li className="list-inline-item social-twitter">
                        <a href="#">
                            <i className="fab fa-twitter"></i>
                        </a>
                    </li>
                    <li className="list-inline-item social-facebook">
                        <a href="#">
                            <i className="fab fa-facebook-f"></i>
                        </a>
                    </li>
                    <li className="list-inline-item social-google-plus">
                        <a href="#">
                            <i className="fab fa-google-plus-g"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    )

}

export default SectionSocial;