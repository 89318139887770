import React from 'react';

import Nav from './containers/Nav';
import Header from './containers/Header';
import SectionDownload from './containers/SectionDownload';
import SectionFeature from './containers/SectionFeature';
import SectionStarter from './containers/SectionStarter';
import Footer from './containers/Footer';
import SectionSocial from './containers/SectionSocial';

function App() {
  return (
    <div className="App">

      <Nav />
      <Header />
      <SectionDownload />
      <SectionFeature />
      <SectionStarter />
      <SectionSocial />
      <Footer />









    </div>
  );
}

export default App;
